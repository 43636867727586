//Theme Base Colour
$theme-color: 'blue';

// Colours (All themes)
$color-verylightgrey: 			#fafafa; // Tile bg color
$color-brownish-grey: 			#717067; // text default
$color-greyish-brown:			#3d3935; //sidebar text

// Colours (surgical)
$color-lightblue-alt: 			#66e0ff; // aka robin-s-egg - Stats counter blue
$color-lightblue:               #00aeef; // aka azure - link
$color-blue:                    #00427a; // aka prussian blue - h1, h2 colour
$color-mediumblue:              #007fbc; // link hover
$color-darkblue:                #002e5e; // h3, h4 colour
$color-superdarkblue:           #001c4d; // search bar color

// $greyish-brown: #3d3935;
// $greyish: #b8b7b3;
// $white-two: #dcdcdc;
// $cerulean: #007fbc;
// $wisteria: #aa8fc3;
// $medium-purple: #733c97;
// $grape: #572772;
// $wheat: #f7c088;
// $pumpkin-orange: #f68b1f;
// $brownish-orange: #c46f19;
// $kelley-green: #008534;
// $light-grey-green: #acd4ab;
// $mid-green: #4aa147;
// $dusty-blue: #6b86b1;

$color-primary: $color-lightblue; //aka azure - e.g. button colour
$color-secondary: $color-blue; //aka prussian blue - 
$color-tertiary: $color-darkblue;

$selection-color: $color-mediumblue;
$selection-text-color: #fff;

//typography
$font-color: $color-brownish-grey;
$highlight-color: $selection-color;
$highlight-text-color: $selection-text-color;
$heading-color: $color-tertiary;
$link-color: $color-lightblue;
$link-color-hover: $color-mediumblue;

//gradients
$gradient-start: $color-primary;
$gradient-end: $color-secondary;
$gradient-hr-bg: linear-gradient(to right, $color-primary, $color-secondary);

//backgrounds
$color-pagination-bg: $color-primary;
$color-sidebar-bg: $color-secondary;

//block specific
$color-stats: $color-lightblue-alt;
$color-tile-arrowbg: $color-mediumblue;

$color-search-bar: $color-superdarkblue;

$stat-counter-bg-image: url('/public/img/jpg/counters-surgical-bg@2x.jpg');
$stat-counter-bg-position-desktop: 100% 50%;

$sidebar-triangle-background: url('/public/img/png/ic/triangle-marineblue.png');

$selectize-filter-bg: $color-mediumblue;

// Mobile
// --Menu
$mobile-menu-toggle-arrow-bg-image: url('/public/img/svg/ic/expand-surgical.svg');
$mobile-menu-toggler-bg: $color-mediumblue;
//lvl1
$mobile-menu-lvl1-bg: $color-tertiary;
$mobile-menu-lvl1-bg-active: $color-mediumblue;
//lvl2
$mobile-menu-item-lvl2: $color-brownish-grey;
//lvl3
$mobile-menu-item-lvl3: $color-brownish-grey;